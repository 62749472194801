export const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
    }; 

export const dateToText = (dateString) => {
        try {
          const dateObj = new Date(dateString);
          const options = { year: 'numeric', month: 'long', day: 'numeric' };
          return dateObj.toLocaleDateString(undefined, options);
        } catch (error) {
          return "Invalid date format";
        }
      };
      