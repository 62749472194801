import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ApiRadioList = (props) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const {event} = useParams();

  let apiUrl = 'https://xdjj-vqgo-2lfl.f2.xano.io/api:YSHYreNv/getEvents_withInscriptionEnCours_typeJPO';

  if(event === "jpo"){
     apiUrl = 'https://xdjj-vqgo-2lfl.f2.xano.io/api:YSHYreNv/getEvents_withInscriptionEnCours_typeJPO';

  }
  else if(event === "reunion"){ 
    apiUrl = "https://xdjj-vqgo-2lfl.f2.xano.io/api:YSHYreNv/getEvents_withInscriptionEnCours_typeReunion";

  }
  

  useEffect(() => {
    // Fetch data from the API using Axios and useEffect
    axios.get(apiUrl)
      .then((response) => {

        setOptions(response.data); // Assuming the API response is an array of options
        setSelectedOption(response.data[0].dateDebut); // Set the default selected option

        props.onChildValueChange(response.data[0]);
   
   

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [apiUrl]);


  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    props.onChildValueChange(event.target);


  };


  const dateToText = (dateString) => {
    try {
      const dateObj = new Date(dateString);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return dateObj.toLocaleDateString(undefined, options);
    } catch (error) {
      return "Invalid date format";
    }
  };




  const formatTimestampToHour = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`;
    return formattedTime;
  }

  return (
    <>
    <label className='obligatoire'></label>
     <div className='radioContainer'>
        
    {options.map((option) => (
   
        <div key={option.id} className='choiceList'>
            <label className=''>
            <input
                type="radio"
                value={option.dateDebut}
                id = {option.id}
                name='anneelumiere_events_id'
                checked={selectedOption === option.dateDebut}
                onChange={handleOptionChange}
            />
            le {dateToText(option.dateDebut)} à {formatTimestampToHour(option.HeureEvent)} {option.situation ?  ' - ' + option.situation : ""}
            </label>
        </div>
      ))}
    </div>
    </>
  );
};

export default ApiRadioList;
