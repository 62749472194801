import React from 'react';
import { useParams } from 'react-router-dom';

const DisplayText = () => {
  let { event } = useParams();
  let eventText = ""


  if(event === "jpo"){
    eventText = "Réunion Portes Ouvertes"; 

  }
  else if(event === "reunion"){
    eventText = "à une Réunion"

  }
  else{ 
    eventText = "Réunion Portes Ouvertes"; 

  }

 
  return (
      <>
      {eventText}
      </>      
  );
};

export default DisplayText;