import React, { useEffect, useState, useRef } from 'react';
import DOMPurify from 'dompurify';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import DisplayText from './components/DisplayText.js';
import ApiRadioList from './components/ApiRadioList.js';
import { validateEmail, dateToText } from './utils/utils.js';

//TODO:recaptachaKey
const recaptchaKey = '6LfLuvQoAAAAADfRWpFUUM2aiIBQnoh0Rm9gAGzY';

const Form = () => {
  const arrCommentConnu = [
    'Par la mission locale',
    'Par les réseaux sociaux',
    'Par les médias',
    'Par un coach',
    'Par un jeune qui a déjà fait le programme',
    'Par un salon',
    'Par une connaissance, par le bouche à oreille ',
    'Par une recherche internet',
    'Un enseignant, le CIO, le proviseur du lycée…',
    'Autre',
  ];
  const arrCommentConnuNeedsAutre = [
    'Par les réseaux sociaux',
    'Autre',
    'Par les médias',
    'Par un coach',
    'Par un salon',
  ];

  const [formData, setFormData] = useState({
    // Initialize form data state with default values
    nom: '',
    prenom: '',
    email: '',
    typeContact: 'Elève',
    commentConnu: '',
    anneelumiere_events_id: [],
    champLibreAutre_typeContact: '',
    champLibre_CConnu: '',
    certify: false,
    dateDebut: '',
    isAlumni: false,
    eventID: 0,
    statut: 'Inscrit JPO',
  });
  // Init variables
  const [inputErrors, setInputErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState('Elève');
  const [selectedOption, setSelectedOption] = useState('');
  const [needsAutre, setNeedsAutre] = useState(false);

  // gestion des input de type "RADIO"
  const handleChoiceChange = (event) => {
    setSelectedChoice(event.target.value);
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: DOMPurify.sanitize(value),
    }));
  };

  // gestion du component  ApiRadioList
  const handleApiRadioListValue = (value) => {
    const formDatacopy = { ...formData };
    formDatacopy.anneelumiere_events_id = [parseInt(value.id)];
    formDatacopy.eventID = parseInt(value.id);
    formDatacopy.dateDebut = value.value;
    setFormData(formDatacopy);
  };

  const reloadLocation = () => {
    setShowPopup(false);
    window.location.reload();
  };

  const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);

  const handleRecaptchaChange = (value) => {
    // Store the reCAPTCHA response value in the component's state
    setIsRecaptchaValid(!!value);

    setFormData((prevFormData) => ({
      ...prevFormData,
      handleRecaptchaChange: value,
    }));
  };

  // Event handler for select change
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);

    const { name, value } = event.target;

    if (arrCommentConnuNeedsAutre.includes(value)) {
      setNeedsAutre(true);
    } else {
      setNeedsAutre(false);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: DOMPurify.sanitize(value),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: e.target.checked, // Store the checked value for checkboxes
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: DOMPurify.sanitize(value),
      }));
    }
  };

  // ERREUR MESSAGE SERVEUR
  const emailError = 'Veuillez renseignez un e-mail valide';
  const nomError = 'Veuillez renseignez un nom valide';
  const prenomError = 'Veuillez renseignez un prénom valide';
  const typeContactError = 'Etes vous un parent ou un élève, ou autre ?';
  const certifyError =
    'Veuillez certifier avoir pris connaissance de notre Politique de protection des données et l’accepter en tous termes';

  const validateForm = () => {
    // Check for errors
    const errors = {};

    const { nom, prenom, email, typeContact, certify, commentConnu } = formData;

    if (handleRecaptchaChange == '') {
      errors.robot = "Vous devez affirmer que vous n'êtes pas un robot !";
    }

    if (!nom || nom.length > 50) {
      errors.nom = nomError;
    }
    if (!prenom) {
      errors.prenom = prenomError;
    }

    if (!validateEmail(email)) {
      errors.email = emailError;
    }

    /*if(typeContact !== "Elève" || typeContact !== "Parent" || typeContact !== "Autre" ||  typeContact !== "Professionnel de l'Education"){
  errors.typeContact = typeContactError;
}

if(!arrCommentConnu.includes(commentConnu)){
  errors.commentConnu = "Veuillez saisir une réponse valide à Comment avez-vous connu Année Lumière"


}*/

    // Validate mandatory inputs in Les informations liées à votre projet
    if (!certify) {
      // setFormError('Please certify that you have read and accept the Data Protection Policy.');
      errors.certify = certifyError;
    }

    // Update inputErrors state

    setInputErrors(errors);

    // Display error messages
    if (Object.keys(errors).length > 0) {
      toast.error(`Il y a ${Object.keys(errors).length} erreurs dans le formulaire, veuillez les corriger`, {
        position: 'top-center',
        autoClose: 1400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });

      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var cancelSubmit = false;

    const UrlgetContactValidationByEmail = `https://xdjj-vqgo-2lfl.f2.xano.io/api:YSHYreNv/getContactByEventByEmail?eventID=${formData.anneelumiere_events_id[0]}&contactEmail=${formData.email}`;

    // check si contact est déjà inscrit à cette évènement.
    if (formData.email !== null && formData.email !== '' && formData.anneelumiere_events_id.length !== 0) {
      await axios
        .get(UrlgetContactValidationByEmail)
        .then((response) => {
          const isContactExist = response.data;

          if (isContactExist.result) {
            // dont do anything and send back a notification to user
            toast.warning(`Vous vous êtes déjà inscrit à l'évènement du ${isContactExist.dateDebut}`, {
              isLoading: false,
              position: 'top-center',
              autoClose: 1400,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });

            cancelSubmit = true;
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }

    if (!cancelSubmit && validateForm()) {
      setIsSubmit(1);

      try {
        const formDataToSend = new FormData();
        for (const [key, value] of Object.entries(formData)) {
          formDataToSend.append(key, value);
        }

        var response = await axios.post(
          'https://xdjj-vqgo-2lfl.f2.xano.io/api:YSHYreNv/anneelumiere_contacts',
          formDataToSend
        );

        if (response.status === 200) {
          setIsSubmit(3);
          // création du pop après 2000ms

          setTimeout(() => {
            setShowPopup(true);
          }, 1000);
        } else {
          toast.error('Erreur lors de la soumission, veuillez ressayer', {
            isLoading: false,
            position: 'top-center',
            autoClose: 1400,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });

          setIsSubmit(2);
        }
      } catch (error) {
        // Handle fetch error

        if (error.response && error.response.status === 401) {
          toast.error("Erreur lors de la soumission, vous n'êtes pas autorisé");
        } else {
          toast.error('Erreur lors de la soumission, veuillez ressayer');
        }

        const erreurServeur = error.response.data.message;

        if (erreurServeur === 'Missing param: email') {
          toast.error(emailError, {
            isLoading: false,
            position: 'top-center',
            autoClose: 1400,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }

        setIsSubmit(2);
      } finally {
      }
    }

    if (isSubmit !== 0 || !isRecaptchaValid) {
      return; // Prevent form submission if already submitting or reCAPTCHA is invalid
    }
  };

  return (
    <div id="form-sos" onSubmit={handleSubmit}>
      <ToastContainer />
      <div className="form-container">
        <div className="form-row">
          <div className="col100">
            <div className="columnWrap">
              <div className="widgetWrap">
                <div id="form-titleContainer">
                  <div className="widgetContainer">
                    <h1>
                      Inscription <DisplayText />
                      <br />
                      <br />
                      <a href="">
                        <img
                          className="logoAL"
                          src="https://www.annee-lumiere.org/wp-content/uploads/2021/07/AnneeLumiere-logo.png"
                        ></img>
                      </a>
                    </h1>
                  </div>
                </div>

                <div id="form-formContainer">
                  <div className="widgetContainer">
                    <form method="post" name="Formulaire SOS Toiture">
                      <div className="form-wrapper">
                        <div className="formSection">
                          <b>Vos coordonnées</b>
                        </div>

                        <div className="form-input">
                          <label className="obligatoire">Nom</label>
                          <input
                            type="text"
                            placeholder="Insérez votre nom"
                            name="nom"
                            value={formData.nom}
                            onChange={handleInputChange}
                          />
                          {inputErrors.nom && <div className="redError">{inputErrors.nom}</div>}
                        </div>

                        <div className="form-input">
                          <label className="obligatoire">Prénom</label>
                          <input
                            placeholder="Insérez votre prénom"
                            type="text"
                            name="prenom"
                            value={formData.prenom}
                            onChange={handleInputChange}
                          />
                          {inputErrors.prenom && <div className="redError">{inputErrors.prenom}</div>}
                        </div>

                        <div className="form-input">
                          <label className="obligatoire">Email</label>
                          <input
                            placeholder="Insérez votre email"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                          {inputErrors.email && <div className="redError">{inputErrors.email}</div>}
                        </div>

                        <div className="form-input">
                          <label className="obligatoire">Vous êtes...</label>

                          <div className="radioContainer">
                            <label className="radioInput">
                              <input
                                name="typeContact"
                                type="radio"
                                value="Elève"
                                checked={selectedChoice === 'Elève'}
                                onChange={handleChoiceChange}
                              />
                              Elève
                            </label>
                            <label className="radioInput">
                              <input
                                name="typeContact"
                                type="radio"
                                value="Parent"
                                checked={selectedChoice === 'Parent'}
                                onChange={handleChoiceChange}
                              />
                              Parent
                            </label>

                            <label className="radioInput">
                              <input
                                name="typeContact"
                                type="radio"
                                value="Professionnel de l'Education"
                                checked={selectedChoice === "Professionnel de l'Education"}
                                onChange={handleChoiceChange}
                              />
                              Professionnel de l'éducation
                            </label>

                            <label className="radioInput">
                              <input
                                name="typeContact"
                                type="radio"
                                value="Autre"
                                checked={selectedChoice === 'Autre'}
                                onChange={handleChoiceChange}
                              />
                              Autre
                            </label>
                          </div>
                        </div>

                        {selectedChoice === 'Autre' && (
                          <div className="form-input commentWrap">
                            <input
                              placeholder="Insérez votre commentaire"
                              type="autre"
                              name="champLibreAutre_typeContact"
                              value={formData.champLibreAutre_typeContact}
                              onChange={handleInputChange}
                            />
                          </div>
                        )}
                      </div>

                      <div className="dividerForm">
                        <hr />
                      </div>

                      <div className="formSection radioList">
                        <b>Choisissez la date à laquelle vous souhaitez participer à notre réunion porte ouverte </b>

                        <ApiRadioList onChildValueChange={handleApiRadioListValue} />
                      </div>

                      <div className="form-input checkboxContainer2">
                        {inputErrors.infosMarket && <div className="redError">{inputErrors.infosMarket}</div>}
                      </div>

                      <div className="dividerForm">
                        <hr />
                      </div>

                      <div className="form-input col70">
                        <label>Comment avez-vous connu Année Lumière ? </label>
                        <select
                          className="selectStyle"
                          placeholder="précisez votre réponse"
                          name="commentConnu"
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          {arrCommentConnu.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        {needsAutre && (
                          <div className="form-input col70 marginUp">
                            <input
                              placeholder="Insérez votre commentaire"
                              type="autre"
                              name="champLibre_CConnu"
                              value={formData.champLibre_CConnu}
                              onChange={handleInputChange}
                            />
                          </div>
                        )}
                      </div>

                      <div className="form-input">
                        {isSubmit !== 3 ? (
                          <ReCAPTCHA
                            sitekey={recaptchaKey}
                            name="handleRecaptchaChange"
                            onChange={handleRecaptchaChange}
                          />
                        ) : (
                          <></>
                        )}
                        {inputErrors.robot && <div className="redError">{inputErrors.robot}</div>}
                      </div>

                      <div className="form-input checkboxContainer2">
                        <div className="field-group">
                          <span className="field-option">
                            <input
                              className="field-checkbox"
                              type="checkbox"
                              name="certify"
                              id="certify"
                              value="Je certifie avoir pris connaissance de la Politique de protection des données et l’accepte en tous termes"
                              onChange={handleInputChange}
                            />
                            <label for="certify" className="field-notes" id="autorite">
                              Je certifie avoir pris connaissance de la{' '}
                              <a
                                id="rgpd"
                                target="_blank"
                                href="https://www.annee-lumiere.org/privacy-policy/"
                                rel="noopener noreferrer"
                              >
                                Politique de protection des données
                              </a>{' '}
                              et l’accepte en tous termes
                            </label>
                          </span>
                        </div>
                        {inputErrors.certify && <div className="redError">{inputErrors.certify}</div>}
                      </div>

                      <div className="btn-submit">
                        <button
                          type="submit"
                          disabled={formData.handleRecaptchaChange === '' ? true : false || !isRecaptchaValid}
                          className={isSubmit !== 0 ? 'isSubmit' : ''}
                        >
                          {isSubmit === 1
                            ? 'Inscription en cours de traitement... ne quittez pas la page'
                            : isSubmit === 2
                              ? "Echec de l'inscription, veuillez reessayer"
                              : isSubmit === 3
                                ? 'Inscription envoyée !'
                                : 'Envoyer'}
                        </button>

                        {/*<button type="submit" className={isSubmit !== 0 ? ('isSubmit'):('')}>{isSubmit === 1 ? ('Demande en cours de traitement... ne quittez pas la page'): isSubmit === 2 ?('Echec de la demande, veuillez reessayer'):isSubmit === 3 ?('Demande envoyée !'):("Envoyer")}</button>*/}
                      </div>
                    </form>
                    {showPopup && (
                      <div className="popup">
                        <div className="popup-content">
                          <h3>Votre inscription a bien été enregistrée !</h3>
                          <p>
                            Vous allez recevoir prochainement un e-mail de confirmation de prise en charge de votre
                            inscription.
                          </p>

                          <button onClick={() => reloadLocation()}>Fermer</button>
                          <div className="rdv">
                            <span>
                              rendez-vous sur{' '}
                              <a rel="noreferrer noopener" href="https://www.annee-lumiere.org/">
                                annee-lumiere.org
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="cgv">
                    <div className="widgetContainer">
                      <p>
                        Les données signalées par un * sont obligatoires. Vos données à caractère personnel sont
                        collectées par ANNEE LUMIERE pour son intérêt légitime dans le but de traiter votre demande.
                        Vous disposez des droits suivants : droit d’accès, droit de rectification, droit à l’effacement
                        (« droit à l’oubli »), droit à la limitation et droit d’opposition. Pour exercer vos droits,
                        vous êtes invités à contacter ANNEE LUMIERE aux coordonnées suivantes :{' '}
                        <span className="redEdilians">contact@annee-lumiere.org</span> Pour plus d’information sur le
                        traitement de vos données à caractère personnel, vous pouvez consulter notre politique en
                        cliquant{' '}
                        <span className="redEdilians">
                          <a
                            className="redEdilians"
                            href="https://www.annee-lumiere.org/privacy-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            ici
                          </a>
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
